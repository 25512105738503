import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;
`;

export const Container = styled.div`
  margin: 24px;
`;

export const EmailLineItem = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;