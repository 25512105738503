import { Logo, Text } from '@freight-nexus/ui';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';

export const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Styled.Container>
      <Styled.HoverContainer
        onClick={() => {navigate('/')}}
      >
        <Logo />
      </Styled.HoverContainer>
      <Styled.HoverContainer>
        <Styled.SimpleRow
          onClick={() => {navigate('/profile')}}
        >
          <FaUser size={18}/>
          <Text type='text-t1'>My Account</Text>
        </Styled.SimpleRow>
      </Styled.HoverContainer>
    </Styled.Container>
  );
};
