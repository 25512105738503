import { GlobalStyle, webTheme } from '@freight-nexus/ui';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './router';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <ThemeProvider theme={webTheme}>
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <Container>
            <Navbar />
            <PageContent>
              <Routes />
            </PageContent>
            
          </Container>
          <Footer />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
