import { Button } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 100px auto 0 auto;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.div`
  margin-top: 50px;
  width: calc(100% - 24px);
  max-width: 550px;

  > * {
    width: 100%;
  }
  > :not(:last-child) {
    margin-bottom: 12px;
  }

  > :first-child {
    margin-bottom: 36px;
  }

  > ${Button}:last-of-type {
    margin-top: 36px;
  }

  a {
    color: ${({ theme }) => theme.palette.black};
  }
`;
