import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  overflow-y: auto;
`;

export const Content = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 550px 700px;
  grid-column-gap: 24px;
`;

export const WelcomeMessageContainer = styled.div`
  text-align: center;

  max-width: 800px;

  > :first-child {
    margin-bottom: 12px;
  }
`;
export const List = styled.div`
  > :not(:last-child) {
    margin-bottom: 12px;
  }

  > :first-child {
    margin-bottom: 36px;
  }
`;

export const ListItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

export const Line = styled.div`
  display: flex;
  align-items: flex-end;

  > .to {
    margin-bottom: 2px;
  }

  > :not(:last-child) {
    margin-right: 6px;
  }
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  margin-right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.red};

  > :hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const FieldValuesGrid = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 8px;
`;

export const SpaceEvenlyRow = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const MatchArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MatchesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: 24px;
`;
