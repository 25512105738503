import {
  ICapacity,
  IFreight
} from '@freight-nexus/db/src/types';
import { Button, Modal, Text } from '@freight-nexus/ui';
import React, { useEffect, useState } from 'react';

import { UserAPI } from '../../api';
import { DateUtils } from '../../utils';

import { CardType } from '.';
import * as Styled from './styles';

interface CardProps {
  // user: IUser;
  freight?: IFreight;
  capacity?: ICapacity;
  deleteItem?: (id: string) => Promise<void>;
  isSelected: boolean;
  setIsSelected: (card: any, cardType: any) => void;
}

export const Card = ({
  // user,
  freight,
  capacity,
  deleteItem,
  isSelected,
  setIsSelected
}: CardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState<string | undefined>();

  const _deleteItem = async () => {
    if (freight && deleteItem) {
      await deleteItem(freight.id);
    } else if (capacity && deleteItem) {
      await deleteItem(capacity.id);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (freight?.brokerId) {
        const res = await UserAPI.getById({ id: freight.brokerId });
        if (res?.user) {
          setUserEmail(res.user.email);
        }
      } else if (capacity?.carrierId) {
        const res = await UserAPI.getById({ id: capacity.carrierId });
        if (res?.user) {
          setUserEmail(res.user.email);
        }
      }
    })();
  }, []);

  return (
    <>
      {freight && (
        <Styled.CardContainer
          isSelected={isSelected}
          onClick={() => setIsSelected(freight, CardType.Freight)}
        >
          <Styled.ListItem>
            <Styled.Lane>
              <Text type="display-h3" secondary>
                Pickup
              </Text>
              <Text type="display-h3" secondary>
                Delivery
              </Text>
              <Text type="display-h2">{freight.origin}</Text>
              <Text type="display-h2">{freight.destination}</Text>
              <Text type="text-t1">
                {DateUtils.getDateString(freight.pickupDate)}
              </Text>
              <Text type="text-t1">
                {DateUtils.getDateString(freight.deliveryDate)}
              </Text>
            </Styled.Lane>

            <Text type="text-t1">Temperature: {freight.temperature}</Text>
            {freight.email ? (
              <Text type="text-t1">Email: {freight.email}</Text>
            ) : (
              <Text type="text-t1">Email: {userEmail}</Text>
            )}

            <Styled.Notes>
              <Text type="text-t1">Notes: {freight.notes}</Text>
            </Styled.Notes>

            {deleteItem && (
              <Styled.Actions>
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  action="secondary"
                  size="md"
                >
                  Delete
                </Button>
              </Styled.Actions>  
            )}
          </Styled.ListItem>
        </Styled.CardContainer>
      )}
      {capacity && (
        <Styled.CardContainer
          isSelected={isSelected}
          onClick={() => setIsSelected(capacity, CardType.Capacity)}
        >
          <Styled.ListItem>
            <Styled.Lane>
              <Text type="display-h3" secondary>
                Origin
              </Text>
              <Text type="display-h3" secondary>
                Destination
              </Text>
              <Text type="display-h2"> {capacity.origin}</Text>
              <Text type="display-h2"> {capacity.destination}</Text>
              <Text type="text-t1">
                {DateUtils.getDateString(capacity.availableStart)}
              </Text>
              <Text type="text-t1">
                {DateUtils.getDateString(capacity.availableEnd)}
              </Text>
            </Styled.Lane>

            <Text type="text-t1">Equipment: {capacity.equipment}</Text>
            <Text type="text-t1">Weight Limit: {capacity.weightLimit}</Text>
            <Text type="text-t1">Target Rate: {capacity.targetRate}</Text>
            {capacity.email ? (
              <Text type="text-t1">Email: {capacity.email}</Text>
            ) : (
              <Text type="text-t1">Email: {userEmail}</Text>
            )}

            <Styled.Notes>
              <Text type="text-t1">Notes: {capacity.notes}</Text>
            </Styled.Notes>
            {deleteItem && (
              <Styled.Actions>
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  action="secondary"
                  size="md"
                >
                  Delete
                </Button>
              </Styled.Actions>
            )}
          </Styled.ListItem>
        </Styled.CardContainer>
      )}
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <Text type="display-h2">
            Are you sure you want to delete this item?
          </Text>
          <Styled.Row>
            <Button onClick={() => setIsModalOpen(false)} action="secondary">
              Cancel
            </Button>
            <Button onClick={_deleteItem} action="primary">
              Delete
            </Button>
          </Styled.Row>
        </Modal>
      )}
    </>
  );
};
