import { ICapacity, IFreight, IUser } from '@freight-nexus/db/src/types';
import { Button, Modal, Text } from '@freight-nexus/ui';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import React, { useEffect,useState } from 'react';
import { FaTrash } from 'react-icons/fa';

import { UserAPI } from '../../api';

import * as Styled from './styles';

interface CardProps {
  freight?: IFreight;
  capacity?: ICapacity;
  showContact?: Boolean;
  deleteItem?: (id: string) => Promise<void>;
}

dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);

export const Card = ({ 
  freight, 
  capacity, 
  showContact = false, 
  deleteItem 
}: CardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matchedUser, setMatchedUser] = useState<IUser>();

  useEffect(() => {
    if (showContact && freight?.brokerId) {
      UserAPI.getById({ id: freight.brokerId }).then((res) => {
        if (res?.user) {
          setMatchedUser(res.user);
        }
      });
    } else if (showContact && capacity?.carrierId) {
      UserAPI.getById({ id: capacity.carrierId }).then((res) => {
        if (res?.user) {
          setMatchedUser(res.user);
        }
      });
    }
  })

  const getDateStringFreight = (date: Date) => {
    return dayjs(date).subtract(0, 'hours').format('ddd, MMM D • h:mm A');
  };

  const getDateStringCapacity = (date: Date) => {
    return dayjs(date).subtract(0, 'hours').second(0).millisecond(0);
  };

  const getCapacityLabel = (capacity: ICapacity) => {
    const start = getDateStringCapacity(capacity.availableStart);
    const end = getDateStringCapacity(capacity.availableEnd);
    let label = `${start.format('ddd, MMM D • h:mm A')} - ${end.format('ddd, MMM D • h:mm A')}`;
    if (start.isSame(end, 'day')) {
      label = `${start.format('ddd, MMM D • h:mm A')} - ${end.format('h:mm A')}`;
    }
    return label;
  };

  const _deleteItem = async () => {
    if (freight && deleteItem) {
      await deleteItem(freight.id);
    } else if (capacity && deleteItem) {
      await deleteItem(capacity.id);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      {freight && (
        <Styled.ListItem>
          <Styled.SpaceBetweenRow>
            <Styled.Line>
              <Text type="display-h1">{freight.origin}</Text>
              <Text type="text-t2" className="to" secondary>
                to
              </Text>
              <Text type="display-h1">{freight.destination}</Text>
            </Styled.Line>
            {deleteItem && (
              <Styled.IconContainer onClick={() => setIsModalOpen(true)}>
                <FaTrash />
              </Styled.IconContainer>
            )}
          </Styled.SpaceBetweenRow>
          <Styled.FieldValuesGrid>
            <Text type="display-h3">Pickup:</Text>
            <Text type="text-t2">
              {getDateStringFreight(freight.pickupDate)}
            </Text>
            <Text type="display-h3">Delivery:</Text>
            <Text type="text-t2">
              {getDateStringFreight(freight.deliveryDate)}
            </Text>
            <Text type="display-h3">Temperature:</Text>
            <Text type="text-t2">{freight.temperature}</Text>
            {freight.notes && (
              <>
                <Text type="display-h3">Notes:</Text>
                <Text type="text-t2">{freight.notes}</Text>
              </>
            )}
            {showContact && matchedUser && (
              <>
                <Text type="display-h3">Email:</Text>
                <Text type="text-t2">{matchedUser.email.toLowerCase()}</Text>
                {matchedUser.phone && (
                  <>
                    <Text type="display-h3">Phone:</Text>
                    <Text type="text-t2">{matchedUser.phone}</Text>
                  </>
                )}
              </>
            )}
          </Styled.FieldValuesGrid>
        </Styled.ListItem>
      )}
      {capacity && (
        <Styled.ListItem key={capacity.id}>
          <Styled.SpaceBetweenRow>
            <Styled.Line>
              <Text type="display-h1">{capacity.origin}</Text>
              <Text type="text-t2" className="to" secondary>
                to
              </Text>
              <Text type="display-h1">{capacity.destination}</Text>
            </Styled.Line>
            {deleteItem && (
              <Styled.IconContainer onClick={() => setIsModalOpen(true)}>
                <FaTrash />
              </Styled.IconContainer>
            )}
          </Styled.SpaceBetweenRow>
          <Styled.FieldValuesGrid>
            <Text type="display-h3">Availability:</Text>
            <Text type="text-t2">{getCapacityLabel(capacity)}</Text>
            {/* {(hoursDiff > 0 || minutesDiff > 0) && (
            <>
              <Text type="display-h3">Hours Available:</Text>
              <Text type="text-t2">
                {hoursDiff > 0 ? `${hoursDiff} hours` : ''}{' '}
                {minutesDiff > 0 ? `${minutesDiff} minutes` : ''}
              </Text>
            </>
          )} */}
            {capacity.equipment && (
              <>
                <Text type="display-h3">Equipment:</Text>
                <Text type="text-t2">{capacity.equipment}</Text>
              </>
            )}
            {capacity.weightLimit && (
              <>
                <Text type="display-h3">Weight Limit:</Text>
                <Text type="text-t2">{capacity.weightLimit} lbs</Text>
              </>
            )}
            {capacity.targetRate && (
              <>
                <Text type="display-h3">Target Rate:</Text>
                <Text type="text-t2">${capacity.targetRate}/mile</Text>
              </>
            )}
            {capacity.notes && (
              <>
                <Text type="display-h3">Notes:</Text>
                <Text type="text-t2">{capacity.notes}</Text>
              </>
            )}
            {showContact && matchedUser && (
              <>
                <Text type="display-h3">Email:</Text>
                <Text type="text-t2">{matchedUser.email.toLowerCase()}</Text>
                {matchedUser.phone && (
                  <>
                    <Text type="display-h3">Phone:</Text>
                    <Text type="text-t2">{matchedUser.phone}</Text>
                  </>
                )}
              </>
            )}
          </Styled.FieldValuesGrid>
        </Styled.ListItem>
      )}
      {isModalOpen && (
        <Modal title="Delete Item" onClose={() => setIsModalOpen(false)}>
          <Styled.Container>
            <Text type="display-h2">
              Are you sure you want to delete this item?
            </Text>
            <Styled.SpaceBetweenRow>
              <Button onClick={() => setIsModalOpen(false)} action="secondary">
                Cancel
              </Button>
              <Button onClick={_deleteItem} action="primary">
                Delete
              </Button>
            </Styled.SpaceBetweenRow>
          </Styled.Container>
        </Modal>
      )}
    </>
  );
};
