import { API } from "@freight-nexus/types";

import { request } from "./base";

const route = API.Freight.route;

export const create = request<API.Freight.Create.Input, API.Freight.Create.Output>(
  route,
  API.Freight.Create.name
);

export const getAll = request<API.Freight.GetAll.Input, API.Freight.GetAll.Output>(
  route,
  API.Freight.GetAll.name
);

export const getAllUnmatched = request<API.Freight.GetAllUnmatched.Input, API.Freight.GetAllUnmatched.Output>(
  route,
  API.Freight.GetAllUnmatched.name
);

export const updateStatus = request<API.Freight.UpdateStatus.Input, API.Freight.UpdateStatus.Output>(
  route,
  API.Freight.UpdateStatus.name
);

export const deleteById = request<API.Freight.DeleteById.Input, API.Freight.DeleteById.Output>(
  route,
  API.Freight.DeleteById.name
);