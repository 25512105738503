import { API } from '@freight-nexus/types';

import { request } from './base';

const route = API.AI.route;

export const extractLoadInfo = request<
  API.AI.ExtractLoadInfo.Input,
  API.AI.ExtractLoadInfo.Output
>(route, API.AI.ExtractLoadInfo.name);
