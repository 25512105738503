import { HydratedMatch } from '@freight-nexus/db/src/types';
import { Button, Modal, Text } from '@freight-nexus/ui';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MatchAPI } from '../../api';
import { Card } from '../Admin/card';

import * as Styled from './styles';

export const MatchesView = () => {
  const [matches, setMatches] = useState<HydratedMatch[]>([]);
  const [matchToBeDeleted, setMatchToBeDeleted] =
    useState<HydratedMatch | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // fetch matches
    (async () => {
      const res = await MatchAPI.getAll();
      if (res?.matches) {
        setMatches(res.matches);
      }
    })();
  }, []);

  const deleteMatch = async () => {
    if (!matchToBeDeleted) return;
    const toDeleteId = matchToBeDeleted.id;
    setMatches(matches.filter((match) => match.id !== toDeleteId));
    setMatchToBeDeleted(null);

    await MatchAPI.deleteById({ id: matchToBeDeleted.id });
  };

  return (
    <>
      <Styled.Header>
        <Button
          onClick={() => {
            navigate('/admin');
          }}
          action="primary"
        >
          Back
        </Button>
        <Text type="display-h1">Matches</Text>
        <Styled.ThreeCol>
          {matches.map((match: HydratedMatch) => (
            <>
              <div>
                <Text type="display-h2">Freight</Text>
                <Styled.Scrollable>
                  <Card
                    // user={load.brokerId}
                    key={match.freight.id}
                    freight={match.freight}
                    isSelected={false}
                    setIsSelected={() => {}}
                  />
                </Styled.Scrollable>
              </div>
              <Styled.MatchArrowContainer>
                <FaArrowRight size={70} />
                <Button onClick={() => setMatchToBeDeleted(match)}>
                  Delete Match
                </Button>
              </Styled.MatchArrowContainer>
              <div>
                <Text type="display-h2">Capacity</Text>
                <Styled.Scrollable>
                  <Card
                    // user={truck.carrierId}
                    key={match.capacity.id}
                    capacity={match.capacity}
                    isSelected={false}
                    setIsSelected={() => {}}
                  />
                </Styled.Scrollable>
              </div>
            </>
          ))}
        </Styled.ThreeCol>
      </Styled.Header>
      {matchToBeDeleted && (
        <Modal title="Delete Match" onClose={() => setMatchToBeDeleted(null)}>
          <Text type="text-t1">
            Deleting this will move the load/truck back to the admin page.
          </Text>
          <Styled.Row>
            <Button
              onClick={() => setMatchToBeDeleted(null)}
              action="secondary"
            >
              Cancel
            </Button>
            <Button onClick={deleteMatch} action="primary">
              Delete
            </Button>
          </Styled.Row>
        </Modal>
      )}
    </>
  );
};
