import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;

export const TwoCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.palette.black};
  padding: 10px 24px;

  > * {
    max-height: 95vh;

    display: flex;
    flex-direction: column;
  }
`;

export const Scrollable = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  flex: 1;

  padding: 8px;
  border: 1px solid ${({ theme }) => theme.palette.black};

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const MatchArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;
