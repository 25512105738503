import { API } from '@freight-nexus/types';

import { request } from './base';

export const create = request<API.Match.Create.Input, API.Match.Create.Output>(
  API.Match.route,
  API.Match.Create.name
);

export const getAll = request<API.Match.GetAll.Input, API.Match.GetAll.Output>(
  API.Match.route,
  API.Match.GetAll.name
);

export const deleteById = request<
  API.Match.DeleteById.Input,
  API.Match.DeleteById.Output
>(API.Match.route, API.Match.DeleteById.name);

export const getByUserId = request<
  API.Match.GetByUserId.Input,
  API.Match.GetByUserId.Output
>(API.Match.route, API.Match.GetByUserId.name);

export const autoMatch = request<
  API.Match.AutoMatch.Input,
  API.Match.AutoMatch.Output
>(API.Match.route, API.Match.AutoMatch.name);
