import { Text } from '@freight-nexus/ui';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const TwoCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  max-height: 95vh;
  border-top: 1px solid ${({ theme }) => theme.palette.black};
  padding: 10px 24px;

  > * {
    max-height: 95vh;

    display: flex;
    flex-direction: column;
  }
`;

export const MatchArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const ThreeCol = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  column-gap: 12px;
  max-height: 95vh;
  border-top: 1px solid ${({ theme }) => theme.palette.black};
  padding: 10px 24px;

  > * {
    max-height: 95vh;

    display: flex;
    flex-direction: column;
  }
`;

export const Scrollable = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  flex: 1;

  padding: 8px;
  border: 1px solid ${({ theme }) => theme.palette.black};

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CardContainer = styled.div<{
  isSelected?: boolean;
}>`
  ${({ isSelected, theme }) =>
    isSelected ? `
      background-color: ${theme.palette.primary};
      > :hover {
        cursor: pointer;
      }
    ` : `
      background-color: ${theme.palette.white};
      > :hover {
        background-color: ${theme.palette.primaryLight};
        cursor: pointer;
      }
  `}
`

export const EmailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 24px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;

  > :last-child {
    margin-top: 8px;
    display: flex;
    > :not(:last-child) {
      margin-right: 40px;
    }
  }
`;

export const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: auto 250px 250px;
  grid-gap: 12px;
  align-items: center;
`;

export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ListItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  ${Text} {
    word-wrap: break-word;
  }
`;

export const Lane = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 4px;

  background-color: ${({ theme }) => theme.palette['grey-500']};
  border-radius: 8px;
  padding: 4px;
`;

export const FieldValuesGrid = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 8px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Notes = styled.div`
  width: 500px;
  * {
    word-wrap: break-word;
  }
`;

export const Forms = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;