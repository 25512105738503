import { API } from '@freight-nexus/types';

import { request } from './base';

const route = API.User.route;

export const getMe = request<API.User.GetMe.Input, API.User.GetMe.Output>(
  route,
  API.User.GetMe.name
);

export const getById = request<API.User.GetById.Input, API.User.GetById.Output>(
  route,
  API.User.GetById.name
);

export const getFreight = request<
  API.User.GetFreight.Input,
  API.User.GetFreight.Output
>(route, API.User.GetFreight.name);

export const getCapacity = request<
  API.User.GetCapacity.Input,
  API.User.GetCapacity.Output
>(route, API.User.GetCapacity.name);

export const getUsers = request<
  API.User.GetUsers.Input,
  API.User.GetUsers.Output
>(route, API.User.GetUsers.name);

export const update = request<API.User.Update.Input, API.User.Update.Output>(
  route,
  API.User.Update.name
);