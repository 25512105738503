import { API } from '@freight-nexus/types';

import { request } from './base';

const route = API.Email.route;

export const create = request<API.Email.Create.Input, API.Email.Create.Output>(
  route,
  API.Email.Create.name
);

export const getById = request<API.Email.GetById.Input, API.Email.GetById.Output>(
  route,
  API.Email.GetById.name
);

export const getAll = request<API.Email.GetAll.Input, API.Email.GetAll.Output>(
  route,
  API.Email.GetAll.name
);

export const deleteById = request<
  API.Email.DeleteById.Input,
  API.Email.DeleteById.Output
>(route, API.Email.DeleteById.name);

export const getUnprocessedEmails = request<
  API.Email.GetUnprocessedEmails.Input,
  API.Email.GetUnprocessedEmails.Output
>(route, API.Email.GetUnprocessedEmails.name);

export const createAndProcessEmail = request<
  API.Email.CreateAndProcessEmail.Input,
  API.Email.CreateAndProcessEmail.Output
>(route, API.Email.CreateAndProcessEmail.name);
