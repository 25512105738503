import { LoadingOverlay } from '@freight-nexus/ui';
import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as RouteWrapper
} from 'react-router-dom';

import { useAuthContext } from '../contexts/AuthContext';
import {
  Admin,
  AutoMatchesView,
  EmailView,
  MatchesView,
  Profile,
  Root, 
  SignIn, 
  SignUp
} from '../pages';

const AuthedRoutes = () => {
  const { user, loaded } = useAuthContext();

  return loaded ? (
    user ? (
      <Outlet />
    ) : (
      <Navigate to="/signin" />
    )
  ) : (
    <LoadingOverlay size="lg" />
  );
};

const UnAuthedRoutes = () => {
  const { user, loaded } = useAuthContext();

  return loaded ? (
    !user ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <LoadingOverlay size="lg" />
  );
};

const Routes = () => {
  return (
    <RouteWrapper>
      <Route element={<AuthedRoutes />}>
        {/* AUTHED ROUTES */}
        <Route path="/" element={<Root />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/email/:id" element={<EmailView />} />
        <Route path="/admin/matches" element={<MatchesView />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin/autoMatches" element={<AutoMatchesView />} />
      </Route>
      <Route element={<UnAuthedRoutes />}>
        {/* UN-AUTHED ROUTES */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </RouteWrapper>
  );
};

export default Routes;
