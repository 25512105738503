import { API } from "@freight-nexus/types";

import { request } from "./base";

const route = API.Capacity.route;

export const create = request<API.Capacity.Create.Input, API.Capacity.Create.Output>(
  route,
  API.Capacity.Create.name
);

export const getAll = request<API.Capacity.GetAll.Input, API.Capacity.GetAll.Output>(
  route,
  API.Capacity.GetAll.name
);

export const getAllUnmatched = request<API.Capacity.GetAllUnmatched.Input, API.Capacity.GetAllUnmatched.Output>(
  route,
  API.Capacity.GetAllUnmatched.name
);

export const updateStatus = request<API.Capacity.UpdateStatus.Input, API.Capacity.UpdateStatus.Output>(
  route,
  API.Capacity.UpdateStatus.name
);

export const deleteById = request<API.Capacity.DeleteById.Input, API.Capacity.DeleteById.Output>(
  route,
  API.Capacity.DeleteById.name
);