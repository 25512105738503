import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const SimpleRow = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const HoverContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;