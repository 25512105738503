import { Text } from '@freight-nexus/ui';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 50px 24px;
  display: flex;
  justify-content: end;
  border-top: 1px solid ${({ theme }) => theme.palette['grey-500']};

  a {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Footer = () => {
  return (
    <Container>
      <Content>
        <Text type="text-t2">Got any questions? Contact us at</Text>
        <Text type="text-t2">
          <a
            href="mailto:team@freightnexus.ai"
            target="_blank"
            rel="noreferrer"
          >
            team@freightnexus.ai
          </a>
        </Text>
      </Content>
    </Container>
  );
};
