import {
  HydratedMatch,
  ICapacity,
  IFreight,
  UserType
} from '@freight-nexus/db/types';
import { Button, Text } from '@freight-nexus/ui';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { BsArrowLeftRight } from 'react-icons/bs';

import { CapacityAPI, FreightAPI, MatchAPI, UserAPI } from '../../api';
import { BrokerForm, CarrierForm } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';

import { Card } from './card';
import * as Styled from './styles';

dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);

// const localTimezone = dayjs.tz.guess();

const InstructionString = {
  Broker:
    'Please enter the details of your freight below. We will not share your information with anyone. You will get an email when we find a carrier using our AI-powered matchmaking system.',
  Carrier:
    'Please enter the details of your truck below. We will not share your information with anyone. You will get an email when we find freight using our AI-powered matchmaking system.'
};

enum TabOptions {
  Current = 'Current',
  Matches = 'Matches',
  Outdated = 'Outdated'
}

export const Root = () => {
  const { user } = useAuthContext();
  const [loads, setLoads] = useState<IFreight[]>([]);
  const [trucks, setTrucks] = useState<ICapacity[]>([]);
  const [matches, setMatches] = useState<HydratedMatch[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabOptions>(
    TabOptions.Current
  );

  useEffect(() => {
    (async () => {
      if (user!.type === UserType.Broker) {
        const res = await UserAPI.getFreight();
        if (res?.freight) {
          setLoads(res.freight);
        }
      } else {
        const res = await UserAPI.getCapacity();
        if (res?.capacity) {
          setTrucks(res.capacity);
        }
      }

      // fetch matches
      const res = await MatchAPI.getByUserId({ userId: user!.id });
      if (res?.matches) {
        setMatches(res.matches);
      }
    })();
  }, [user!.type]);

  const getCurrentLoads = () => {
    return loads.filter((load) => {
      return dayjs(load.pickupDate).isAfter(dayjs());
    });
  };

  const getCurrentTrucks = () => {
    return trucks.filter((truck) => {
      return dayjs(truck.availableStart).isAfter(dayjs());
    });
  };

  const getOutdatedLoads = () => {
    return loads.filter((load) => {
      return dayjs(load.pickupDate).isBefore(dayjs());
    });
  };

  const getOutdatedTrucks = () => {
    return trucks.filter((truck) => {
      return dayjs(truck.availableStart).isBefore(dayjs());
    });
  };

  const getTrucksToDisplay = () => {
    switch (selectedTab) {
      case TabOptions.Current:
        return getCurrentTrucks();
      case TabOptions.Matches:
        return trucks;
      case TabOptions.Outdated:
        return getOutdatedTrucks();
    }
  };

  const getLoadsToDisplay = () => {
    switch (selectedTab) {
      case TabOptions.Current:
        return getCurrentLoads();
      case TabOptions.Matches:
        return loads;
      case TabOptions.Outdated:
        return getOutdatedLoads();
    }
  };

  return (
    <Styled.Container>
      <Styled.WelcomeMessageContainer>
        <Text type="display-h2">
          Welcome to an early version of our platform!
        </Text>
        <Text type="text-t1">
          {user!.type === UserType.Broker
            ? InstructionString.Broker
            : InstructionString.Carrier}
        </Text>
      </Styled.WelcomeMessageContainer>
      <Styled.Content>
        {user!.type === UserType.Broker ? (
          <>
            <BrokerForm
              onCreate={(load) => {
                setLoads((prev) => [...prev, load]);
              }}
            />
            <Styled.List>
              <Styled.SpaceEvenlyRow>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Current)}
                  action={
                    selectedTab === TabOptions.Current ? 'primary' : 'secondary'
                  }
                >
                  Your Loads: {getCurrentLoads().length}
                </Button>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Matches)}
                  action={
                    selectedTab === TabOptions.Matches ? 'primary' : 'secondary'
                  }
                >
                  Matched Loads: {matches.length}
                </Button>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Outdated)}
                  action={
                    selectedTab === TabOptions.Outdated
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  Old Loads: {getOutdatedLoads().length}
                </Button>
              </Styled.SpaceEvenlyRow>
              {selectedTab === TabOptions.Matches ? (
                <Styled.MatchesGrid>
                  <Text type="display-h2">Your Load</Text>
                  <div />
                  <Text type="display-h2">Matched Carrier</Text>
                  {matches.map((match: HydratedMatch) => (
                    <>
                      <Card freight={match.freight} />
                      <Styled.MatchArrowContainer>
                        <BsArrowLeftRight size={24} />
                      </Styled.MatchArrowContainer>
                      <Card 
                        capacity={match.capacity} 
                        showContact={true}
                      />
                    </>
                  ))}
                </Styled.MatchesGrid>
              ) : (
                <>
                  {getLoadsToDisplay().map((load: IFreight) => {
                    return (
                      <Card
                        freight={load}
                        deleteItem={async (id) => {
                          await FreightAPI.deleteById({ id });
                          setLoads((prev) =>
                            prev.filter((item) => item.id !== id)
                          );
                        }}
                      />
                    );
                  })}
                </>
              )}
            </Styled.List>
          </>
        ) : (
          <>
            <CarrierForm
              onCreate={(truck) => {
                setTrucks((prev) => [...prev, truck]);
              }}
            />
            <Styled.List>
              <Styled.SpaceEvenlyRow>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Current)}
                  action={
                    selectedTab === TabOptions.Current ? 'primary' : 'secondary'
                  }
                >
                  Your Trucks: {getCurrentTrucks().length}
                </Button>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Matches)}
                  action={
                    selectedTab === TabOptions.Matches ? 'primary' : 'secondary'
                  }
                >
                  Matched Trucks: {matches.length}
                </Button>
                <Button
                  onClick={() => setSelectedTab(TabOptions.Outdated)}
                  action={
                    selectedTab === TabOptions.Outdated
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  Old Trucks: {getOutdatedTrucks().length}
                </Button>
              </Styled.SpaceEvenlyRow>
              {selectedTab === TabOptions.Matches ? (
                <Styled.MatchesGrid>
                  <Text type="display-h2">Your Truck</Text>
                  <div />
                  <Text type="display-h2">Matched Broker</Text>
                  {matches.map((match: HydratedMatch) => (
                    <>
                      <Card capacity={match.capacity} />
                      <Styled.MatchArrowContainer>
                        <BsArrowLeftRight size={24} />
                      </Styled.MatchArrowContainer>
                      <Card 
                        freight={match.freight} 
                        showContact={true}
                      />
                    </>
                  ))}
                </Styled.MatchesGrid>
              ) : (
                getTrucksToDisplay().map((truck) => {
                  return (
                    <Card
                      capacity={truck}
                      deleteItem={async (id) => {
                        await CapacityAPI.deleteById({ id });
                        setTrucks((prev) =>
                          prev.filter((item) => item.id !== id)
                        );
                      }}
                    />
                  );
                })
              )}
            </Styled.List>
          </>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
