import { ICapacity } from '@freight-nexus/db/types';
import { API } from '@freight-nexus/types';
import { Button, Modal, Text } from '@freight-nexus/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MatchAPI } from '../../api';
import { Card } from '../Admin/card';

import * as Styled from './styles';

export const AutoMatchesView = () => {
  const [locationBased, setLocationBased] = useState<boolean>(false);
  const [autoMatches, setAutoMatches] = useState<API.Match.AutoMatch.Output>({
    dateMatches: [],
    locationMatches: []
  });
  const [selectedMatch, setSelectedMatch] = useState<
    API.Match.AutoMatch.Output['dateMatches'][number] | null
  >(null);
  const [showMatchModal, setShowMatchModal] = useState<ICapacity | null>(null);

  const matches = useMemo(() => {
    if (locationBased) {
      return autoMatches.locationMatches;
    } else {
      return autoMatches.dateMatches;
    }
  }, [locationBased, autoMatches]);

  const navigate = useNavigate();

  useEffect(() => {
    // fetch matches
    (async () => {
      const res = await MatchAPI.autoMatch();
      if (res) {
        setAutoMatches(res);
      }
    })();
  }, []);

  const createMatch = async () => {
    if (!selectedMatch || !showMatchModal) {
      return;
    }

    await MatchAPI.create({
      freightId: selectedMatch.load.id,
      capacityId: showMatchModal.id,
      brokerId: selectedMatch.load.brokerId,
      carrierId: showMatchModal.carrierId
    });

    setShowMatchModal(null);
    setSelectedMatch(null);
  };

  return (
    <>
      <Styled.Header>
        <Styled.ButtonRow>
          <Button
            onClick={() => {
              navigate('/admin');
            }}
            action="primary"
            size="md"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              setLocationBased(false);
              setSelectedMatch(null);
            }}
            action={locationBased ? 'secondary' : 'primary'}
            size="md"
          >
            Date Matches
          </Button>
          <Button
            onClick={() => {
              setLocationBased(true);
              setSelectedMatch(null);
            }}
            action={locationBased ? 'primary' : 'secondary'}
            size="md"
          >
            Date & Location Matches
          </Button>
        </Styled.ButtonRow>
        <Text type="display-h1">Auto Matches</Text>
        <Styled.TwoCol>
          <Styled.Scrollable>
            {matches.map((match) =>
              match.trucks.length > 0 ? (
                <Card
                  key={match.load.id}
                  freight={match.load}
                  isSelected={
                    !!selectedMatch &&
                    selectedMatch?.load?.id === match?.load?.id
                  }
                  setIsSelected={() => setSelectedMatch(match)}
                />
              ) : null
            )}
          </Styled.Scrollable>
          <Styled.Scrollable>
            {selectedMatch?.trucks?.map((truck) => (
              <Card
                key={truck.id}
                capacity={truck}
                isSelected={!!showMatchModal && showMatchModal.id === truck.id}
                setIsSelected={() => {
                  setShowMatchModal(truck);
                }}
              />
            ))}
            {!selectedMatch && (
              <Text type="display-h3">
                Select a load to see matching trucks
              </Text>
            )}
          </Styled.Scrollable>
        </Styled.TwoCol>
      </Styled.Header>
      {showMatchModal && selectedMatch && (
        <Modal
          onClose={() => {
            setShowMatchModal(null);
          }}
          title="Matched!"
        >
          <Styled.ModalContainer>
            <Text type="text-t1">
              Load: {selectedMatch.load?.origin} to{' '}
              {selectedMatch.load?.destination}
            </Text>
            <Text type="text-t1">
              Truck: {showMatchModal.origin} to {showMatchModal.destination}
            </Text>
            <Styled.ButtonRow>
              <Button
                action="secondary"
                onClick={() => {
                  setShowMatchModal(null);
                }}
              >
                Cancel
              </Button>
              <Button action="primary" onClick={createMatch}>
                Confirm
              </Button>
            </Styled.ButtonRow>
          </Styled.ModalContainer>
        </Modal>
      )}
    </>
  );
};
