import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  overflow-y: auto;
`;

export const Content = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 550px 700px;
  grid-column-gap: 24px;
`;

export const UserInfoContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 75px;

  // apply to all children
  > * {
    margin-bottom: 20px;
  }
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Form = styled.form`
  width: calc(100% - 24px);
  max-width: 550px;
  margin-top: 24px;
  margin-bottom: 250px;

  > * {
    width: 100%;
  }
  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;