import { API } from '@freight-nexus/types';

import { request } from './base';

const route = API.Auth.route;

export const signUp = request<API.Auth.SignUp.Input, API.Auth.SignUp.Output>(
  route,
  API.Auth.SignUp.name
);

export const signIn = request<API.Auth.SignIn.Input, API.Auth.SignIn.Output>(
  route,
  API.Auth.SignIn.name
);
