import { EmailStatus,IEmail } from '@freight-nexus/db/src/types';
import { Button,Modal,Text } from '@freight-nexus/ui';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';

interface EmailRowProps {
  email: IEmail;
  deleteEmail: (id: string) => Promise<void>;
}
export const EmailRow = ({
  email,
  deleteEmail,
}: EmailRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const _deleteEmail = async () => {
    await deleteEmail(email.id);
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  return (
  <>
    {isModalOpen && (
      <Modal
        onClose={() => setIsModalOpen(false)}
      >
        <Text type='display-h2'>Are you sure you want to delete this email?</Text>
        <Styled.Row>
          <Button
            onClick={() => setIsModalOpen(false)}
            action='secondary'
          >
            Cancel
          </Button>
          <Button
            onClick={_deleteEmail}
            action='primary'
          >
            Delete
          </Button>
        </Styled.Row>
      </Modal>
    )}
    <Styled.Row onClick={() => {navigate(`/admin/email/${email.id}`)}}>
      <Styled.Column>
        <Text type='display-h2'>Address</Text>
        <Text type='text-t1'>{email.address}</Text>
      </Styled.Column>
      <Styled.Column>
        <Text type='display-h2'>Subject</Text>
        <Text type='text-t1'>{email.subject}</Text>
      </Styled.Column>
      <Styled.Column>
        <Text type='display-h2'>isProcessed</Text>
        <Text type='text-t1'>{email.status === EmailStatus.Processed ? 'True' : 'False'}</Text>
      </Styled.Column>
      {/* <Styled.Column>
        <Button
          onClick={() => {}}
          action='primary'
        >
          Process
        </Button>
      </Styled.Column> */}
      <Styled.Column>
        <Button
          onClick={() => setIsModalOpen(true)}
          action='secondary'
        >
          Delete
        </Button>
      </Styled.Column>
    </Styled.Row>
  </>
  )
}