import { Button, Input, InputGroup, Text } from '@freight-nexus/ui';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuthContext } from '../../contexts/AuthContext';

import * as Styled from './styles';

export const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { signIn } = useAuthContext();

  const handleSignIn = async () => {
    if (loadingRef.current) {
      return;
    }

    loadingRef.current = true;
    setLoading(true);
    const res = await signIn({ email, password });
    setLoading(false);
    loadingRef.current = false;

    if (!res?.success || res?.error) {
      setError(res.error || 'An error occurred');
    }
  };

  return (
    <Styled.Container>
      <Styled.Form>
        <Text type="display-h1">Sign In</Text>
        <InputGroup header="Email">
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </InputGroup>
        <InputGroup header="Password">
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </InputGroup>
        <Button onClick={handleSignIn} disabled={loading} size="md">
          {loading ? 'Signing In...' : 'Sign In'}
        </Button>
        {error && (
          <Text type="text-t2" color="red">
            {error}
          </Text>
        )}
        <Text type="text-t2">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </Text>
      </Styled.Form>
    </Styled.Container>
  );
};
