import styled from 'styled-components';

export const Form = styled.form`
  width: calc(100% - 24px);
  max-width: 550px;

  > * {
    width: 100%;
  }
  > :not(:last-child) {
    margin-bottom: 12px;
  }

  > :first-child {
    margin-bottom: 36px;
  }
`;
