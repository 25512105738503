import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);

export { dayjs };

export const getDateString = (date: Date) => {
  return dayjs(date).format('MMM D, YYYY HH:mm');
};
