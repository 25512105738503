import 'react-toastify/dist/ReactToastify.css';

import { Button,Input,InputGroup, Text } from '@freight-nexus/ui';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast,ToastContainer } from 'react-toastify';

import { UserAPI } from '../../api';
import { useAuthContext } from '../../contexts/AuthContext';

import * as Styled from './styles';

type Inputs = {
  firstName: string;
  lastName: string;
  phone: string;
}

export const Profile = () => {
  const { user, signOut } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const notifySave = () => toast.success("Profile Saved");

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    await UserAPI.update({
      diff: data
    });
    notifySave();
  }

  const validateForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  }

  return (
    <Styled.Container>
      <Text type='display-h1'>Edit Profile</Text>
      <Styled.Form>
        <InputGroup 
          header='First Name'
          required
          error={errors.firstName ? 'First name is required' : ''}
        >
          <Input
            {...register('firstName', { required: 'First name is required' })}
            defaultValue={user?.firstName}
          />
        </InputGroup>
        <InputGroup 
          header='Last Name'
          required
          error={errors.lastName ? 'Last name is required' : ''}
        >
          <Input
            {...register('lastName', { required: 'Last name is required' })}
            defaultValue={user?.lastName}
          />
        </InputGroup>
        <InputGroup header='Phone'>
          <Input
            {...register('phone')}
            defaultValue={user?.phone ?? ''}
          />
        </InputGroup>
        <Button
          size='md'
          onClick={validateForm}
        >
          Save
        </Button>
      </Styled.Form>
      <Button
        onClick={signOut}
      >
        Sign Out
      </Button>
      <ToastContainer 
        position='top-center'
        autoClose={3000}
      />
    </Styled.Container>
  )
}