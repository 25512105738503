import { EmailStatus,IEmail, IExtractedData } from '@freight-nexus/db/src/types';
import { Button,Text } from '@freight-nexus/ui';
import React, { useEffect,useState } from 'react';
import { useNavigate ,
  useParams
} from 'react-router-dom';

import { EmailAPI } from '../../api';
import { BrokerForm } from '../../components';

import * as Styled from './styles';

export const EmailView = () => {
  const [email, setEmail] = useState<IEmail | null>(null);
  const [extractedData, setExtractedData] = useState<IExtractedData[]>([]);

  const { id: emailId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchEmail = async () => {
      const res = await EmailAPI.getById({ id: emailId ?? ''});
      if (res?.email) {
        setEmail(res.email);
      }
      if (res?.extractedData) {
        console.log(res.extractedData);
        setExtractedData(res.extractedData)
      };
    };
    fetchEmail();
  }, []);

  return (
    <Styled.Container>
      <Button onClick={() => {navigate('/admin')}}>Back</Button>
      <Styled.Header>
        <Text type='display-h1'>Email View Page: {emailId}</Text>
      </Styled.Header>
      <Styled.EmailLineItem>
        <Text type='display-h2'>From: </Text>
        <Text type='text-t1'>{email?.address}</Text>    
      </Styled.EmailLineItem>
      <Styled.EmailLineItem>
        <Text type='display-h2'>Subject: </Text>
        <Text type='text-t1'>{email?.subject}</Text>
      </Styled.EmailLineItem>
      <Styled.EmailLineItem>
        <Text type='display-h2'>Body:</Text>
        <Text type='text-t1'>{email?.body}</Text>
      </Styled.EmailLineItem>
      {email?.attachmentsUrl && (
        <Styled.EmailLineItem>
          <Text type='display-h2'>Download Attachments</Text>
          <a href={email.attachmentsUrl} download>Download</a>
        </Styled.EmailLineItem>
      )}
      <Styled.EmailLineItem>
        <Text type='display-h2'>Status: </Text>
        <Text type='text-t1'>{email?.status}</Text>
      </Styled.EmailLineItem>
      {email?.status && email.status === EmailStatus.Unprocessed && (
        <Button onClick={() => {}}>
          Process Email (this button does nothing)
        </Button>
      )}
      <Styled.Header>
        <Text type='display-h1'>Extracted Data</Text>
      </Styled.Header>
      {extractedData.map((data, index) => (
        <Styled.EmailLineItem key={index}>
          <Text type='display-h2'>Extracted Data {index + 1}</Text>
          <Text type='text-t1'>Origin: {data.origin}</Text>
          <Text type='text-t1'>Destination: {data.destination}</Text>
          <Text type='text-t1'>Temperature: {data.temperature}</Text>
          <Text type='text-t1'>Equipment: {data.equipment}</Text>
          <Text type='text-t1'>Weight Limit: {data.weightLimit}</Text>
          <Text type='text-t1'>Target Rate: {data.targetRate}</Text>
          <Text type='text-t1'>Notes: {data.notes}</Text>
          <Text type='text-t1'>User Type: {data.userType}</Text>
          <BrokerForm onCreate={() => {}} />
        </Styled.EmailLineItem>
      ))}
    </Styled.Container>
  );
}