import { UserType } from '@freight-nexus/db/types';
import { Button, Input, InputGroup, Text } from '@freight-nexus/ui';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuthContext } from '../../contexts/AuthContext';

import * as Styled from './styles';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

enum Error {
  Required = 'This field is required',
  InvalidEmail = 'Invalid email',
  PasswordTooShort = 'Password must be at least 6 characters'
}

export const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [type, setType] = useState<UserType | null>(null);
  const [errors, setErrors] = useState<{
    email?: Error;
    password?: Error;
    firstName?: Error;
    lastName?: Error;
    type?: Error;
    creation?: string;
  }>({});
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { signUp } = useAuthContext();

  const handleSignUp = async () => {
    if (loadingRef.current) {
      return;
    }
    const curErrors = {
      email: email ? undefined : Error.Required,
      password: password ? undefined : Error.Required,
      firstName: firstName ? undefined : Error.Required,
      lastName: lastName ? undefined : Error.Required,
      type: type ? undefined : Error.Required
    };
    const invalidEmail = email && !emailRegex.test(email);
    if (invalidEmail) {
      curErrors.email = Error.InvalidEmail;
    }
    const invalidPassword = password.length < 6;
    if (invalidPassword) {
      curErrors.password = Error.PasswordTooShort;
    }
    if (Object.values(curErrors).some((value) => !!value)) {
      setErrors(curErrors);
      return;
    }

    loadingRef.current = true;
    setLoading(true);
    const res = await signUp({
      email,
      password,
      firstName,
      lastName,
      type: type!
    });
    loadingRef.current = false;
    setLoading(false);

    if (!res?.success || res?.error) {
      setErrors({ ...errors, creation: res?.error ?? 'An error occurred' });
    }
  };

  return (
    <Styled.Container>
      <Text type="display-h1">Welcome to FreightNexus</Text>
      <Styled.Form>
        <Styled.NameRow>
          <InputGroup required header="First Name" error={errors.firstName}>
            <Input
              error={!!errors.firstName}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setErrors({ ...errors, firstName: undefined });
              }}
            />
          </InputGroup>
          <InputGroup required header="Last Name" error={errors.lastName}>
            <Input
              error={!!errors.lastName}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setErrors({ ...errors, lastName: undefined });
              }}
            />
          </InputGroup>
        </Styled.NameRow>
        <InputGroup required header="Email" error={errors.email}>
          <Input
            error={!!errors.email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({ ...errors, email: undefined });
            }}
          />
        </InputGroup>
        <Styled.PasswordRow>
          <InputGroup required header="Password" error={errors.password}>
            <Input
              error={!!errors.password}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors({ ...errors, password: undefined });
              }}
              type="password"
            />
          </InputGroup>
        </Styled.PasswordRow>
        <InputGroup required header="Business Type" error={errors.type}>
          <Styled.TypeRow>
            {Object.values(UserType).map((value) => (
              <Button
                key={value}
                onClick={() => {
                  setType(value);
                  setErrors({ ...errors, type: undefined });
                }}
                action={type === value ? 'primary' : 'secondary'}
                width="100%"
                size="md"
                variant="outlined"
              >
                {value}
              </Button>
            ))}
          </Styled.TypeRow>
        </InputGroup>
        <Button onClick={handleSignUp} size="md" disabled={loading}>
          {loading ? 'Creating...' : 'Create Account'}
        </Button>
        {errors.creation && (
          <Text type="text-t2" color="red">
            {errors.creation}
          </Text>
        )}
        <Text type="text-t2">
          Already have an account? <Link to="/signin">Sign In</Link>
        </Text>
      </Styled.Form>
    </Styled.Container>
  );
};
