import { ICapacity, IUser } from '@freight-nexus/db/types';
import {
  Button,
  DatePicker,
  Input,
  InputGroup,
  Select,
  Text,
  TextArea
} from '@freight-nexus/ui';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CapacityAPI, UserAPI } from '../../api';

import * as Styled from './styles';

type Inputs = {
  origin: string;
  email: string;
  destination: string;
  equipment: string;
  weightLimit: number;
  targetRate: number;
  notes: string;
  assignedUserId?: string;
};

interface CarrierFormProps {
  onCreate: (load: ICapacity) => void;
  isAdmin?: boolean;
}

export const CarrierForm = ({
  onCreate,
  isAdmin = false
}: CarrierFormProps) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [availableStart, setAvailableStart] = useState<Date | undefined>();
  const [availableEnd, setAvailableEnd] = useState<Date | undefined>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<Inputs>();
  const [dateErrors, setDateErrors] = useState<{
    availableStart?: string;
    availableEnd?: string;
  }>({});
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>();

  useEffect(() => {
    if (isAdmin) {
      (async () => {
        const res = await UserAPI.getUsers();
        if (res?.users) {
          setUsers(res.users);
        }
      })();
    }
  }, [isAdmin]);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const endBeforeStart =
      dayjs(availableEnd).isBefore(dayjs(availableStart)) ||
      dayjs(availableEnd).isSame(dayjs(availableStart, 'minute'));
    if (
      !availableStart ||
      !availableEnd ||
      endBeforeStart ||
      loadingRef.current
    ) {
      return;
    }
    const {
      origin,
      email,
      destination,
      equipment,
      weightLimit,
      targetRate,
      notes,
      assignedUserId
    } = data;

    loadingRef.current = true;
    setLoading(true);
    const res = await CapacityAPI.create({
      availableStart,
      availableEnd,
      email,
      origin,
      destination,
      equipment,
      weightLimit,
      targetRate,
      notes,
      assignedUserId:
        assignedUserId !== 'placeholder' ? assignedUserId : undefined
    });
    loadingRef.current = false;
    setLoading(false);
    if (res?.capacity) {
      onCreate(res.capacity);
      setSubmitError(undefined);
      reset();
      setAvailableStart(undefined);
      setAvailableEnd(undefined);
    } else {
      setSubmitError(res?.error || 'An error occurred');
    }
  };

  const validateForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!availableStart || !availableEnd) {
      setDateErrors({
        availableStart: !availableStart
          ? 'Availability start date is required'
          : undefined,
        availableEnd: !availableEnd
          ? 'Availability end date is required'
          : undefined
      });
    } else {
      const endBeforeStart =
        dayjs(availableEnd).isBefore(dayjs(availableStart)) ||
        dayjs(availableEnd).isSame(dayjs(availableStart, 'minute'));
      if (endBeforeStart) {
        setDateErrors({
          availableStart: undefined,
          availableEnd: 'End date must be after start date'
        });
      }
    }
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Styled.Form>
        <Text type="display-h1">Submit a Truck</Text>
        {isAdmin && (
          <>
            <InputGroup header="Assigned User">
              <Select
                {...register('assignedUserId', {
                  required: false
                })}
                defaultValue="placeholder"
              >
                <option value="placeholder" disabled>
                  Select an option
                </option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName} - {user.email}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <InputGroup required header="Email">
              <Input
                {...register('email', { required: false })}
                error={!!errors.email}
              />
            </InputGroup>
          </>
        )}
        <InputGroup
          required
          header="Availability Start"
          error={dateErrors.availableStart}
        >
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={availableStart}
            onChange={(date: Date) => {
              setAvailableStart(date);
              setDateErrors({ ...dateErrors, availableStart: undefined });
            }}
            error={!!dateErrors.availableStart}
          />
        </InputGroup>
        <InputGroup
          required
          header="Availability End"
          error={dateErrors.availableEnd}
        >
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={availableEnd}
            onChange={(date: Date) => {
              setAvailableEnd(date);
              setDateErrors({ ...dateErrors, availableEnd: undefined });
            }}
            error={!!dateErrors.availableEnd}
          />
        </InputGroup>
        <InputGroup
          required
          header="Origin"
          error={errors.origin ? 'Origin is required' : undefined}
        >
          <Input
            {...register('origin', { required: true })}
            error={!!errors.origin}
          />
        </InputGroup>
        <InputGroup
          required
          header="Destination"
          error={errors.destination ? 'Destination is required' : undefined}
        >
          <Input
            {...register('destination', { required: true })}
            error={!!errors.destination}
          />
        </InputGroup>
        <InputGroup header="Equipment">
          <Input
            {...register('equipment', { required: false })}
            error={!!errors.equipment}
          />
        </InputGroup>
        <InputGroup header="Weight Limit (lbs)">
          <Input
            {...register('weightLimit', { required: false })}
            type="number"
            error={!!errors.equipment}
          />
        </InputGroup>
        <InputGroup header="Target Rate ($/mile)">
          <Input
            {...register('targetRate', { required: false })}
            type="number"
            error={!!errors.equipment}
          />
        </InputGroup>
        <InputGroup header="Notes">
          <TextArea {...register('notes')} />
        </InputGroup>
        <Button size="md" onClick={validateForm} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
        {submitError && (
          <Text type="text-t2" color="red">
            {submitError}
          </Text>
        )}
      </Styled.Form>
    </>
  );
};
