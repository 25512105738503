import { IUser } from '@freight-nexus/db/types';
import { API } from '@freight-nexus/types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { AuthAPI, UserAPI } from '../api';

export const ACCESS_TOKEN_KEY = 'wut is that';

export interface IAuthContext {
  user: IUser | undefined | null;
  loaded: boolean;
  signUp: (props: API.Auth.SignUp.Input) => Promise<{
    success: boolean;
    error?: string;
  }>;
  signIn: (
    props: API.Auth.SignIn.Input
  ) => Promise<{ success: boolean; error?: string }>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  loaded: false,
  signUp: async () => ({ success: false }),
  signIn: async () => ({ success: false }),
  signOut: async () => {}
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<IUser | null | undefined>(null);
  const [loaded, setLoaded] = useState(false);

  const initializeAccessToken = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    return accessToken ?? undefined;
  };

  const storeAccessToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  };

  const clearAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  };

  const signOut = useCallback(async () => {
    clearAccessToken();
    setUser(null);
  }, []);

  const refreshUser = useCallback(async () => {
    const res = await UserAPI.getMe({});
    if (res?.user) {
      setUser(res.user);
    } else {
      signOut();
    }
  }, []);

  const signInFromAccessToken = useCallback(async (token?: string) => {
    if (!token) return;
    try {
      await refreshUser();
    } catch (error) {
      console.error('sadge');
    }
  }, []);

  const signIn = useCallback(async (props: API.Auth.SignIn.Input) => {
    const res = await AuthAPI.signIn(props);
    if (res?.accessToken && res?.user) {
      storeAccessToken(res.accessToken);
      setUser(res.user);
      return { success: true };
    } else {
      return { success: false, error: res?.error };
    }
  }, []);

  const signUp = useCallback(async (props: API.Auth.SignUp.Input) => {
    const res = await AuthAPI.signUp(props);
    if (res?.user && res?.accessToken) {
      storeAccessToken(res.accessToken);
      setUser(res.user);
      return { success: true };
    } else {
      return { success: false, error: res?.error };
    }
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = await initializeAccessToken();
      await signInFromAccessToken(accessToken);
      setLoaded(true);
    })();
  }, []);

  const values: IAuthContext = useMemo(
    () => ({
      user,
      loaded,
      signIn,
      signOut,
      signUp
    }),
    [user, loaded, signIn, signOut, signUp]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
