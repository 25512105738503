import { ICapacity, IEmail, IFreight } from '@freight-nexus/db/src/types';
import { Button, DatePicker, Input, Modal, Text } from '@freight-nexus/ui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CapacityAPI, EmailAPI, FreightAPI, MatchAPI } from '../../api';
import { BrokerForm, CarrierForm, EmailRow } from '../../components';
import { DateUtils } from '../../utils';

import { Card } from './card';
import * as Styled from './styles';

enum FilterOptions {
  Origin = 'origin',
  Destination = 'destination',
  StatusVisibility = 'statusVisibility'
}

export enum CardType {
  Freight = 'freight',
  Capacity = 'capacity'
}

export const Admin = () => {
  const [originalLoads, setOriginalLoads] = useState<IFreight[]>([]);
  const [originalTrucks, setOriginalTrucks] = useState<ICapacity[]>([]);
  const [loads, setLoads] = useState<IFreight[]>([]);
  const [trucks, setTrucks] = useState<ICapacity[]>([]);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [originFilter, setOriginFilter] = useState('');
  const [destinationFilter, setDestinationFilter] = useState('');
  const [pickupFromFilter, setPickupFromFilter] = useState<Date | undefined>();
  const [pickupToFilter, setPickupToFilter] = useState<Date | undefined>();
  const [deliveryFromFilter, setDeliveryFromFilter] = useState<
    Date | undefined
  >();
  const [deliveryToFilter, setDeliveryToFilter] = useState<Date | undefined>();
  const [selectedLoad, setSelectedLoad] = useState<IFreight | undefined>();
  const [selectedTruck, setSelectedTruck] = useState<ICapacity | undefined>();
  const [showMatchModal, setShowMatchModal] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const freightResponse = await FreightAPI.getAllUnmatched();
      if (freightResponse?.freights) {
        const freights = freightResponse.freights;
        freights.sort((a, b) => {
          return (
            new Date(a.pickupDate).getTime() - new Date(b.pickupDate).getTime()
          );
        });
        setLoads(freights);
        setOriginalLoads(freights);
      }

      const capacityResponse = await CapacityAPI.getAllUnmatched();
      if (capacityResponse?.capacities) {
        const capacities = capacityResponse.capacities;
        capacities.sort((a, b) => {
          return (
            new Date(a.availableStart).getTime() -
            new Date(b.availableStart).getTime()
          );
        });
        setTrucks(capacities);
        setOriginalTrucks(capacities);
      }

      const emailResponse = await EmailAPI.getAll();
      if (emailResponse?.emails) {
        setEmails(emailResponse.emails);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedLoad && selectedTruck) {
      setShowMatchModal(true);
    }
  }, [selectedLoad, selectedTruck]);

  const onCloseMatchModal = () => {
    setShowMatchModal(false);
    setSelectedLoad(undefined);
    setSelectedTruck(undefined);
  };

  const createMatch = async () => {
    if (selectedLoad && selectedTruck) {
      await MatchAPI.create({
        freightId: selectedLoad.id,
        capacityId: selectedTruck.id,
        brokerId: selectedLoad.brokerId,
        carrierId: selectedTruck.carrierId
      });
      setShowMatchModal(false);
      setSelectedLoad(undefined);
      setSelectedTruck(undefined);

      // hide matched items
      setLoads(loads.filter((load) => load.id !== selectedLoad.id));
      setTrucks(trucks.filter((truck) => truck.id !== selectedTruck.id));
      setOriginalLoads(
        originalLoads.filter((load) => load.id !== selectedLoad.id)
      );
      setOriginalTrucks(
        originalTrucks.filter((truck) => truck.id !== selectedTruck.id)
      );
    }
  };

  const deleteEmail = async (id: string) => {
    await EmailAPI.deleteById({ id });
    setEmails(emails.filter((email) => email.id !== id));
  };

  const deleteFreight = async (id: string) => {
    await FreightAPI.deleteById({ id });
    setLoads(loads.filter((load) => load.id !== id));
  };

  const deleteCapacity = async (id: string) => {
    await CapacityAPI.deleteById({ id });
    setTrucks(trucks.filter((truck) => truck.id !== id));
  };

  useEffect(() => {
    filterData();
  }, [
    originFilter,
    destinationFilter,
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
    originalLoads,
    originalTrucks
  ]);

  const filterData = async () => {
    const filteredLoads = originalLoads.filter((load) => {
      return (
        load.origin.toLowerCase().includes(originFilter.toLowerCase()) &&
        load.destination
          .toLowerCase()
          .includes(destinationFilter.toLowerCase()) &&
        (!pickupFromFilter ||
          DateUtils.dayjs(load.pickupDate).isAfter(
            DateUtils.dayjs(pickupFromFilter)
          )) &&
        (!pickupToFilter ||
          DateUtils.dayjs(load.pickupDate).isBefore(
            DateUtils.dayjs(pickupToFilter)
          )) &&
        (!deliveryFromFilter ||
          DateUtils.dayjs(load.deliveryDate).isAfter(
            DateUtils.dayjs(deliveryFromFilter)
          )) &&
        (!deliveryToFilter ||
          DateUtils.dayjs(load.deliveryDate).isBefore(
            DateUtils.dayjs(deliveryToFilter)
          ))
      );
    });
    setLoads(filteredLoads);
    const filteredTrucks = originalTrucks.filter((truck) => {
      return (
        truck.origin.toLowerCase().includes(originFilter.toLowerCase()) &&
        truck.destination
          .toLowerCase()
          .includes(destinationFilter.toLowerCase()) &&
        (!pickupFromFilter ||
          DateUtils.dayjs(truck.availableStart).isAfter(
            DateUtils.dayjs(pickupFromFilter)
          )) &&
        (!pickupToFilter ||
          DateUtils.dayjs(truck.availableStart).isBefore(
            DateUtils.dayjs(pickupToFilter)
          )) &&
        (!deliveryFromFilter ||
          DateUtils.dayjs(truck.availableEnd).isAfter(
            DateUtils.dayjs(deliveryFromFilter)
          )) &&
        (!deliveryToFilter ||
          DateUtils.dayjs(truck.availableEnd).isBefore(
            DateUtils.dayjs(deliveryToFilter)
          ))
      );
    });
    setTrucks(filteredTrucks);
  };

  const onFilterChange = (text: string, field: FilterOptions) => {
    if (field === FilterOptions.Origin) {
      setOriginFilter(text);
    } else if (field === FilterOptions.Destination) {
      setDestinationFilter(text);
    }
  };

  const selectCard = (card: IFreight | ICapacity, cardType: CardType) => {
    if (cardType === CardType.Freight) {
      if (selectedLoad === card) {
        setSelectedLoad(undefined);
      } else {
        setSelectedLoad(card as IFreight);
      }
    } else if (cardType === CardType.Capacity) {
      if (selectedTruck === card) {
        setSelectedTruck(undefined);
      } else {
        setSelectedTruck(card as ICapacity);
      }
    }
  };

  return (
    <>
      <Styled.Header>
        <Text type="display-h1">Admin Dashboard</Text>
      </Styled.Header>
      <Styled.FiltersContainer>
        <Text type="display-h2">Filters</Text>
        <Styled.FiltersGrid>
          <Text type="display-h3">Lane</Text>

          <Input
            onChange={(e) =>
              onFilterChange(e.target.value, FilterOptions.Origin)
            }
            value={originFilter}
            placeholder="Filter by Origin"
          />
          <Input
            onChange={(e) =>
              onFilterChange(e.target.value, FilterOptions.Destination)
            }
            value={destinationFilter}
            placeholder="Filter by Destination"
          />

          <Text type="display-h3">Pickup Time Range</Text>

          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={pickupFromFilter}
            onChange={(date: Date) => {
              setPickupFromFilter(date);
            }}
            placeholderText="From"
          />
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={pickupToFilter}
            onChange={(date: Date) => {
              setPickupToFilter(date);
            }}
            placeholderText="To"
          />

          <Text type="display-h3">Delivery Time Range</Text>

          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={deliveryFromFilter}
            onChange={(date: Date) => {
              setDeliveryFromFilter(date);
            }}
            placeholderText="From"
          />
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={deliveryToFilter}
            onChange={(date: Date) => {
              setDeliveryToFilter(date);
            }}
            placeholderText="To"
          />
        </Styled.FiltersGrid>
        <Styled.MatchesRow>
          <Button
            onClick={() => {
              navigate('/admin/matches');
            }}
          >
            Show Matches
          </Button>
          <Button
            onClick={() => {
              navigate('/admin/autoMatches');
            }}
          >
            Show Auto Matches
          </Button>
        </Styled.MatchesRow>
        {/* <Button onClick={resetFilters}>Reset Filters</Button> */}
      </Styled.FiltersContainer>
      <Styled.TwoCol>
        <div>
          <Text type="display-h2">Freight</Text>
          <Styled.Scrollable>
            {loads.map((load) => (
              <Card
                // user={load.brokerId}
                key={load.id}
                freight={load}
                deleteItem={deleteFreight}
                isSelected={selectedLoad === load}
                setIsSelected={selectCard}
              />
            ))}
          </Styled.Scrollable>
        </div>
        <div>
          <Text type="display-h2">Capacity</Text>
          <Styled.Scrollable>
            {trucks.map((truck) => (
              <Card
                // user={truck.carrierId}
                key={truck.id}
                capacity={truck}
                deleteItem={deleteCapacity}
                isSelected={selectedTruck === truck}
                setIsSelected={selectCard}
              />
            ))}
          </Styled.Scrollable>
        </div>
      </Styled.TwoCol>
      <Styled.Forms>
        <div>
          <Text type="display-h1">Add Freight</Text>
          <BrokerForm
            onCreate={(load) => setOriginalLoads((prev) => [load, ...prev])}
            isAdmin
          />
        </div>
        <div>
          <Text type="display-h1">Add Capacity</Text>
          <CarrierForm
            onCreate={(truck) => setOriginalTrucks((prev) => [truck, ...prev])}
            isAdmin
          />
        </div>
      </Styled.Forms>
      <Styled.Header>
        <Text type="display-h1">Emails</Text>
      </Styled.Header>
      <Styled.EmailsContainer>
        {emails.map((email) => (
          <EmailRow key={email.id} email={email} deleteEmail={deleteEmail} />
        ))}
      </Styled.EmailsContainer>
      {showMatchModal && (
        <Modal onClose={onCloseMatchModal} title="Matched!">
          <Styled.ModalContainer>
            <Text type="text-t1">
              Load: {selectedLoad?.origin} to {selectedLoad?.destination}
            </Text>
            <Text type="text-t1">
              Truck: {selectedTruck?.origin} to {selectedTruck?.destination}
            </Text>
            <Styled.SimpleRow>
              <Button action="secondary" onClick={onCloseMatchModal}>
                Cancel
              </Button>
              <Button action="primary" onClick={createMatch}>
                Confirm
              </Button>
            </Styled.SimpleRow>
          </Styled.ModalContainer>
        </Modal>
      )}
    </>
  );
};
