import { IFreight, IUser, TemperatureType } from '@freight-nexus/db/types';
import {
  Button,
  DatePicker,
  Input,
  InputGroup,
  Select,
  Text,
  TextArea
} from '@freight-nexus/ui';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FreightAPI, UserAPI } from '../../api';

import * as Styled from './styles';

type Inputs = {
  origin: string;
  email: string;
  destination: string;
  temperature: TemperatureType;
  notes: string;
  assignedUserId?: string;
};

interface BrokerFormProps {
  onCreate: (load: IFreight) => void;
  isAdmin?: boolean;
}

export const BrokerForm = ({ onCreate, isAdmin = false }: BrokerFormProps) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [pickupDate, setPickupDate] = useState<Date | undefined>();
  const [deliveryDate, setDeliveryDate] = useState<Date | undefined>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<Inputs>();
  const [dateErrors, setDateErrors] = useState<{
    pickupDate?: string;
    deliveryDate?: string;
  }>({});
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>();

  useEffect(() => {
    if (isAdmin) {
      (async () => {
        const res = await UserAPI.getUsers();
        if (res?.users) {
          setUsers(res.users);
        }
      })();
    }
  }, [isAdmin]);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const deliveryBeforePickup =
      dayjs(deliveryDate).isBefore(dayjs(pickupDate)) ||
      dayjs(deliveryDate).isSame(dayjs(pickupDate), 'minute');
    if (
      !pickupDate ||
      !deliveryDate ||
      deliveryBeforePickup ||
      loadingRef.current
    ) {
      return;
    }
    const { origin, email, destination, temperature, notes, assignedUserId } =
      data;

    loadingRef.current = true;
    setLoading(true);
    const res = await FreightAPI.create({
      pickupDate,
      deliveryDate,
      email,
      origin,
      destination,
      temperature,
      notes,
      assignedUserId:
        assignedUserId !== 'placeholder' ? assignedUserId : undefined
    });
    loadingRef.current = false;
    setLoading(false);
    if (res?.freight) {
      onCreate(res.freight);
      setSubmitError(undefined);
      reset();
      setPickupDate(undefined);
      setDeliveryDate(undefined);
    } else {
      setSubmitError(res?.error || 'An error occurred');
    }
  };

  const validateForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!pickupDate || !deliveryDate) {
      setDateErrors({
        pickupDate: !pickupDate ? 'Pickup date is required' : undefined,
        deliveryDate: !deliveryDate ? 'Delivery date is required' : undefined
      });
    } else {
      const deliveryBeforePickup =
        dayjs(deliveryDate).isBefore(dayjs(pickupDate)) ||
        dayjs(deliveryDate).isSame(dayjs(pickupDate), 'minute');
      if (deliveryBeforePickup) {
        setDateErrors({
          pickupDate: undefined,
          deliveryDate: 'Delivery date must be after pickup date'
        });
      }
    }
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Styled.Form>
        <Text type="display-h1">Submit a Load</Text>
        {isAdmin && (
          <>
            <InputGroup header="Assigned User">
              <Select
                {...register('assignedUserId', {
                  required: false
                })}
                defaultValue="placeholder"
              >
                <option value="placeholder" disabled>
                  Select an option
                </option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName} - {user.email}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <InputGroup required header="Email">
              <Input
                {...register('email', { required: false })}
                error={!!errors.email}
              />
            </InputGroup>
          </>
        )}
        <InputGroup required header="Pickup Date" error={dateErrors.pickupDate}>
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={pickupDate}
            onChange={(date: Date) => {
              setPickupDate(date);
              setDateErrors({ ...dateErrors, pickupDate: undefined });
            }}
            error={!!dateErrors.pickupDate}
          />
        </InputGroup>
        <InputGroup
          required
          header="Delivery Date"
          error={dateErrors.deliveryDate}
        >
          <DatePicker
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            startDate={new Date()}
            selected={deliveryDate}
            onChange={(date: Date) => {
              setDeliveryDate(date);
              setDateErrors({ ...dateErrors, deliveryDate: undefined });
            }}
            error={!!dateErrors.deliveryDate}
          />
        </InputGroup>
        <InputGroup
          required
          header="Origin"
          error={errors.origin ? 'Origin is required' : undefined}
        >
          <Input
            {...register('origin', { required: true })}
            error={!!errors.origin}
          />
        </InputGroup>
        <InputGroup
          required
          header="Destination"
          error={errors.destination ? 'Destination is required' : undefined}
        >
          <Input
            {...register('destination', { required: true })}
            error={!!errors.destination}
          />
        </InputGroup>
        <InputGroup
          required
          header="Temperature"
          error={errors.temperature ? 'Temperature is required' : undefined}
        >
          <Select
            {...register('temperature', {
              required: true,
              validate: (value) =>
                Object.values(TemperatureType).includes(
                  value as TemperatureType
                )
            })}
            defaultValue="placeholder"
            error={!!errors.temperature}
          >
            <option value="placeholder" disabled>
              Select an option
            </option>
            {Object.values(TemperatureType).map((temp) => (
              <option key={temp} value={temp}>
                {temp}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup header="Notes">
          <TextArea {...register('notes')} />
        </InputGroup>
        <Button size="md" onClick={validateForm} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
        {submitError && (
          <Text type="text-t2" color="red">
            {submitError}
          </Text>
        )}
      </Styled.Form>
    </>
  );
};
